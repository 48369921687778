import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Wyróżnienie i nagrody | Gabinet Elżbieta</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/o-gabinecie/wyroznienie/" />
  </>
);

const Main = styled.main`
  font-family: 'Lato', sans-serif;

  .box-image {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__desc {
      padding: 0 0 35px;
      width: 100%;
    }

    &__desc {
      ${({ theme }) => theme.mqTablet} {
        padding: 0 35px 0 0;
        width: 65%;
     }
    }

    &__photo {
      text-align: center;
      width: 100%;

      ${({ theme }) => theme.mqTablet} {
        text-align: right;
        width: 35%;
      }
    }
  }
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const Decoration = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_1)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <div className="box-image">
          <div className="box-image__desc">
            <p className="subtitle">Uroda beauty expert</p>
            <h1>Wyróżnienie</h1>
            <p>
              Nasz gabinet został sprawdzony przez pismo
              {' '}
              <em>„Uroda Beauty Expert”</em>
              {' '}
              i&nbsp;wyróżniono nas jako gabinet profesjonalny, godny zaufania i&nbsp;polecenia go innym.
            </p>
            <p>
              U nas możesz czuć się bezpiecznie i&nbsp;komfortowo.
            </p>
          </div>
          <div className="box-image__photo">
            <GatsbyImage
              image={getImage(data.magazine)}
              alt="Magazyn Uroda Beauty Expert"
              loading="eager"
            />
          </div>
        </div>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_1: file(relativePath: {regex: "/hero\/1.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    },
    magazine: file(relativePath: {regex: "/gabinet\/uroda-beauty-expert.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 300
        )
      }
    }
  }
`;

export default Decoration;
